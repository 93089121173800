// @refresh reload
import * as Sentry from "@sentry/browser";
import { mount, StartClient } from "@solidjs/start/client";
import { VERSION } from "./app";

if (import.meta.env.PROD) {
  Sentry.init({
    // STAGE
    dsn: "https://9657da5179e3f1227412a58c69ec0d34@o4507907635871744.ingest.us.sentry.io/4508377152880640",
    // ROB STAGE
    // dsn: "https://88a36efd0b55c331e1a1511d7c9f3c6f@o4508422731202560.ingest.us.sentry.io/4508422732513280",
    // ROMA-WEB
    // dsn: "https://168ca7f3007c9b93cb59878cdd04cc44@o4507907635871744.ingest.us.sentry.io/4508370969362432",
    integrations: [
      // solidRouterBrowserTracingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        mask: ["#sign-in-password"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/stage\.roma-web\.pages\.dev/,
      /^https:\/\/www\.romamoulding\.com/,
    ],
    replaysSessionSampleRate: 0.1, //// This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.DEV ? "dev" : "production",
    release: VERSION,
  });
}

mount(() => <StartClient />, document.getElementById("app")!);
