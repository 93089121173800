import { createAsync } from "@solidjs/router";
import { PERMISSION } from ".";
import { getPermissionsCacheFn } from "./getPermissionsCacheFn";

export const createPermissionChecker = (): PermissionChecker => {
  const permissionSet = createAsync(() => getPermissionsCacheFn());

  return new Proxy(
    {},
    {
      get: (target, prop) => {
        if (prop in PERMISSION) {
          // Return the boolean value directly, no function wrapper
          const permissions = permissionSet();
          return (
            permissions?.has(PERMISSION[prop as keyof typeof PERMISSION]) ??
            false
          );
        }
        return false;
      },
    }
  ) as unknown as { [K in keyof typeof PERMISSION]: boolean };
};

export type PermissionChecker = {
  readonly [K in keyof typeof PERMISSION]: boolean;
};
